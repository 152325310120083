import React, { useState, useEffect } from "react";
import Blurb from "../../components/blurb";
import Button from "../../components/button";
import Footer from "../../components/footer";
import NavBar from "../../components/nav/index";
import { Link } from "react-router-dom";
import "./index.scss";

interface Post {
  id: number;
  title: { rendered: string };
  excerpt: { rendered: string };
  link: string;
  featured_media: number;
  author: number;
  _embedded: {
    "wp:featuredmedia": { source_url: string }[];
  };
}

interface Author {
  id: number;
  name: string;
}

function ThePointPage() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [authors, setAuthors] = useState<Record<number, Author>>({});

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    fetch("https://energypointllc.com/news/wp-json/wp/v2/posts?_embed")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch posts");
        }
        return response.json();
      })
      .then((data: Post[]) => {
        setPosts(data);
        fetchAuthors(data);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  };

  const fetchAuthors = (posts: Post[]) => {
    const authorIds = posts.map((post) => post.author);
    const uniqueAuthorIds = Array.from(new Set(authorIds));
    uniqueAuthorIds.forEach((authorId) => {
      fetch(`https://energypointllc.com/news/wp-json/wp/v2/users/${authorId}`)
        .then((response) => response.json())
        .then((data: Author) => {
          setAuthors((prevAuthors) => ({
            ...prevAuthors,
            [authorId]: data,
          }));
        })
        .catch((error) => {
          console.error("Error fetching author:", error);
        });
    });
  };

  const getAuthorName = (authorId: number) => {
    return authors[authorId]?.name || "Unknown Author";
  };

  return (
    <div className="point-page">
      <NavBar internal />
      <div className="content-wrapper">
        <Blurb
          heading="The Point"
          subtext="Energy Point offers a wide selection of technology implementation and land data management services tailored to the Energy industry's land acquisition and data management needs for scalability, forecasting, and reporting."
        />
        <div className="news-grid">
          {posts.map((post) => (
            <div className="article" key={post.id}>
              <h3 className="title">
                <Link to={`/post/${post.id}`} rel="noreferrer">
                  {post.title.rendered}
                </Link>
                <span className="author">{getAuthorName(post.author)}</span>
              </h3>
              {post.featured_media > 0 &&
                post._embedded["wp:featuredmedia"] && (
                  <img
                    src={post._embedded["wp:featuredmedia"][0].source_url}
                    alt="Featured"
                  />
                )}
              <p
                className="content"
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
              <Button href={`/post/${post.id}`}>Read More</Button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ThePointPage;
