import React, { useLayoutEffect } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/home/index";
import TeamPage from "./pages/team";
import ConsultingPage from "./pages/consulting";
import ServicesPage from "./pages/services";
import ContactPage from "./pages/contact";
import ThePointPage from "./pages/point";
import PostPage from "./pages/post";

interface Props {
  children: any;
}

const Wrapper = ({ children }: Props) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Router basename="/">
      <Wrapper>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/consulting" element={<ConsultingPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/the-point" element={<ThePointPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/post/:postId" element={<PostPage />} />
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
