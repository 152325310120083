import React, { useState } from "react";
import NavBar from "../../components/nav/index";
import "./index.scss";
import donnaKing from "./img/donna-king.jpg";
import johnRoss from "./img/john-ross-everett.jpg";
import michaelaDierinzo from "./img/michaela-dierinzo.jpg";
import tracyLongcrier from "./img/tracy-longcrier.jpg";
import britanniRutledge from "./img/britanni-rutledge.jpg";
import lindsayLucas from "./img/lindsay-lucas.jpg";
import kindraElder from "./img/kindra-elder.jpg";
import Footer from "../../components/footer";
import Button from "../../components/button/index";

const Team = [
  {
    name: "Donna King",
    title: "Founder & CEO",
    bio: "<p>Donna King founded Energy Point to serve a niche in the energy data management and contract services market. With 19 years of experience working for private and public operators in multiple basins, Donna leads the company in providing high-quality services and innovative solutions for clients. She excels in large-scale ERP implementations and data cleansing projects. Donna is dedicated to education and fostering collaborative teams with a delivery focused business model. She has been a featured speaker at major industry events including NADOA, NALTA, CAPDOA, ALTX, and many other organizations. </p>",
    pic: donnaKing,
  },
  {
    name: "John Everett",
    title: "Chief Revenue Officer",
    bio: "<p>John “JR” Everett is serving as the Chief Revenue Officer & brings 15 years of experience in Land E&P, managing and developing key portfolio assets for a publicly traded Fortune 500 company and a large private equity investment group.</p><p> JR has extensive experience in several basins, including Marcellus, Anadarko, Eagle Ford, Permian and Delaware across the US. He was appointed as a Land Representative to serve on multiple best practice committees to develop, streamline and implement workflows to increase interdepartmental communication between the Land department and Business Unit divisions as well as worked closely with IT teams to develop software to improve management of the land assets.</p><p>He was also a lead member of Owner Outreach Programs, where he led a series of informational meetings educating the public on current law, division of interest, and future drilling development. JR’s broad background in Land E&P, land systems, data management, reporting, operations, and acquisitions and divestitures, brings a unique perspective and advantage for clients to his role at Energy Point.</p>",
    pic: johnRoss,
  },
  {
    name: "Britanni Rutledge",
    title: "Chief Analytics Officer",
    bio: "Boasting 13 years in data analytics, consulting and business transformations, Britanni has a proven track record of driving scalable growth. Her purpose lies in translating data into strategic insights, guiding businesses through data transformative journeys with a focus on scalable solutions. Her dedication to delivering impactful results ensures the alignment of analytics to organizational goals.",
    pic: britanniRutledge,
  },
  {
    name: "Lindsay Lucas",
    title: "Chief Strategy Officer and General Counsel",
    bio: "Lindsay joined the Energy Point team in 2023 as Chief Strategy Officer and General Counsel, assisting clients with software selection, data conversions, and data implementations. Prior to joining EPC, Lindsay managed a division order department at a large private equity-backed E&P company. During that time, she was heavily involved in the A&D process, along with spearheading data implementations and software conversions for accounting and land data. Lindsay is a licensed attorney in the state of Oklahoma, holding a JD from the University of Oklahoma. In her free time she enjoys traveling, reading, and playing with her two dogs, Kiwi and Simba.",
    pic: lindsayLucas,
  },
  {
    name: "Kindra Elder",
    title: "Director of Accounting",
    bio: "Kindra Elder joined Energy Point in 2023 with 10+ years experience in the Oil and Gas Industry. Prior to Energy Point, Kindra held roles in multiple divisions of Accounting at Chesapeake Energy. Kindra is experienced with process implementation, software conversion, process improvement, reporting and management of business accounting operations.",
    pic: kindraElder,
  },
  {
    name: "Michaela Dierinzo",
    title: "Land Administration Lead",
    bio: "Michaela Dierinzo joined Energy Point in July 2021 with 10 years of experience in the Oil & Gas Industry. Prior to joining Energy Point, Michaela held a variety of leadership roles in Land Administration at ConocoPhillips and has extensive experience with land system conversion and project leadership.",
    pic: michaelaDierinzo,
  },
  {
    name: "Tracy Longcrier ",
    title: "Executive Office Administrator",
    bio: "Dedicated to a positive work environment, fostering collaboration and employee well-being, Tracy ensures that the Energy Point team operates smoothly. She is an accomplished executive assistant, with a proven track record of seamlessly managing administrative tasks and optimizing organizational efficiency. Her commitment extends beyond administrative duties; as a cultural champion and human resource advocate.",
    pic: tracyLongcrier,
  },
];
interface ExpandedMembers {
  [name: string]: boolean;
}

function TeamPage() {
  const [expandedMembers, setExpandedMembers] = useState<ExpandedMembers>({});

  const toggleMember = (name: string) => {
    setExpandedMembers((prevExpanded) => ({
      ...prevExpanded,
      [name]: !prevExpanded[name],
    }));
  };
  return (
    <div className="team-page">
      <NavBar internal />
      <div className="content-wrapper">
        <h1>Our team of creators and innovators.</h1>
        <p className="subtext">
          Energy Point's team is skilled, diverse and experienced to help
          businesses grow.
        </p>
        <div className="team-block">
          {Team.map((member) => {
            return (
              <div className="team-member" key={member.name}>
                <div className="bio-pic">
                  <img src={member.pic} alt={member.name} />
                </div>
                <div className="copy">
                  <span className="name">{member.name}</span>
                  <span className="title">{member.title}</span>
                  <div
                    className={`bio${
                      expandedMembers[member.name] ? " expand" : "collapse"
                    }`}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expandedMembers[member.name]
                          ? member.bio
                          : `${member.bio.slice(0, 200)}...`,
                      }}
                    />
                    <Button onClick={() => toggleMember(member.name)}>
                      {expandedMembers[member.name] ? "Show Less" : "Read More"}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default TeamPage;
